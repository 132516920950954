// Imports => React
import React, { cloneElement, useMemo, memo } from 'react';

// Imports => Utilities
import { AcIsSet, AcIsBoolean } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

const AcCheckPermissions = ({ allowed, not_allowed, children, ...rest }) => {
	const { can, cannot, role, debug_mode } = usePermissions();

	const renderChildren = useMemo(() => {
		const debug_props = {};

		if (debug_mode) {
			debug_props['data-debug-permission'] = allowed;
		}

		if (!AcIsSet(children)) {
			return null;
		} else if (AcIsSet(allowed) && AcIsBoolean(allowed) && allowed) {
			return cloneElement(children, {
				...rest,
				...debug_props,
			});
		} else if (AcIsSet(allowed) && can(allowed)) {
			return cloneElement(children, {
				...rest,
				...debug_props,
			});
		} else if (AcIsSet(not_allowed) && cannot(not_allowed)) {
			return cloneElement(children, {
				...rest,
				...debug_props,
			});
		} else {
			return null;
		}
	}, [allowed, not_allowed, children, debug_mode]);

	return renderChildren;
};

export default memo(AcCheckPermissions);
