// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-confirmation-modal',
  CENTERED: 'ac-confirmation-modal--centered',
  CONTENT: 'ac-confirmation-modal__content',
};

const AcConfirmationModal = ({
  store: { ui },
  instance = {
    is_busy: false,
  },
  content,
  centered = true,
  cancel = {
    label: 'Cancel',
    callback: () => {},
  },
  confirm = {
    label: 'Yes, delete',
    callback: () => {},
    close: true,
  },
  close = true,
}) => {
  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (cancel && cancel.callback) await cancel.callback();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleConfirm = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.persist) event.persist();

    if (confirm && confirm.callback)
      await confirm
        .callback()
        .then(() => {
          if (close) ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        })
        .catch(() => {
          if (close) ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        });
  };

  const getCancelButtonOptions = useMemo(() => {
    if (!cancel) return {};

    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: cancel.label || 'Cancel',
      callback: handleCancel,
    };
  }, [cancel]);

  const getConfirmButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      title: confirm.label || 'Save',
      callback: handleConfirm,
    };
  }, [confirm]);

  const renderContent = useMemo(() => {
    if (!AcIsSet(content)) return null;

    return (
      <AcRow className={'h-margin-y-5'}>
        {typeof content === 'string' && (
          <AcColumn>
            <AcRichContent content={content} />
          </AcColumn>
        )}
        {typeof content !== 'string' && <AcColumn>{content}</AcColumn>}
      </AcRow>
    );
  }, [content]);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN, centered && _CLASSES.CENTERED]);
  }, [centered]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <AcContainer fluid>
          {content && renderContent}

          <AcRow className={'h-margin-top-40'}>
            {cancel && (
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: cancel && cancel.label,
                    }}
                  />
                </AcButton>
              </AcColumn>
            )}

            <AcColumn
              xxs={12}
              xs={cancel ? 5 : 12}
              sm={cancel ? 6 : 12}
              className={
                cancel ? 'h-text--align-right' : 'h-text--align-center'
              }
            >
              {confirm && (
                <AcButton {...getConfirmButtonOptions}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: confirm && confirm.label,
                    }}
                  />
                </AcButton>
              )}
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>

      {instance && instance.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcConfirmationModal));
